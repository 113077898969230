<route>
{
  "meta": {
    "permission": [
      "customer.change_customer"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="`${$t('edit')} ${$tc('customer', 1)}`"
            :loading="loading"
          >
          </i-toolbar>
          <v-card-text>
            <diallevel
              v-model="dialogCustomer"
              :to-edit="toEditLevel"
              @done="addLevel"
            />
            <dialterms
              v-model="dialogTerms"
              :to-edit="toEditTerms"
              @done="addTerms"
            />
            <v-row class="pt-4">
              <!-- Concurrencia del cliente -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('concurrence') }}</h6>
                <div class="container-v-switch pt-2">
                  <label
                    :class="customer.permanent_customer ? '' : 'active'"
                    for="permanent_customer"
                  >
                    {{ $t('sporadic') }}
                  </label>
                  <v-switch
                    id="permanent_customer"
                    class="mt-0 pt-0"
                    color="primary"
                    name="permanent_customer"
                    v-model="customer.permanent_customer"
                  ></v-switch>
                  <label
                    :class="customer.permanent_customer ? 'active' : ''"
                    for="permanent_customer"
                  >
                    {{ $t('permanent') }}
                  </label>
                </div>
              </v-col>

              <!-- Activo -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('status') }}</h6>
                <v-switch
                  class="v-switch mt-0 pt-0"
                  color="primary"
                  name="active"
                  :label="$t('active')"
                  v-model="customer.active"
                ></v-switch>
              </v-col>

              <!-- Notificaciones -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('notifications') }}</h6>
                <v-switch
                  class="v-switch mt-0"
                  color="primary"
                  name="notification"
                  :label="$t('active')"
                  v-model="customer.notification"
                ></v-switch>
              </v-col>

              <!-- Nombre de la empresa -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="company_name"
                  :name="$t('company_name')"
                  :rules="customer.permanent_customer ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="
                      customer.permanent_customer ? $t('required_field') : ''
                    "
                    :label="$t('company_name')"
                    v-model="customer.company_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Nombres -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  :name="$t('first_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text required-icon"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('first_name')"
                    v-model="customer.first_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Apellidos -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  :name="$t('last_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('last_name')"
                    v-model="customer.last_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Correo -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="email"
                  :name="$tc('email', 1)"
                  :rules="
                    customer.permanent_customer ? 'required|email' : 'email'
                  "
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="email-input"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="
                      customer.permanent_customer ? $t('required_field') : ''
                    "
                    :label="$tc('email', 1)"
                    v-model="customer.email"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Teléfono -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="phone"
                  rules="required|numeric"
                  :name="$tc('phone', 1)"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    counter
                    maxlength="14"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('phone_number')"
                    v-model="customer.phone"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Tipo de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="level"
                  rules="required"
                  :name="$tc('level', 1)"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    item-text="name"
                    item-value="pk"
                    outlined
                    persistent-hint
                    :append-icon="levels.length < 4 ? 'fa-plus' : ''"
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :items="levels"
                    :label="$tc('level', 1)"
                    v-model="customer.level_id"
                    @click:append="openLevel()"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Origen de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="origin"
                  rules="required"
                  :name="$t('customer_origin')"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    persistent-hint
                    item-text="name"
                    item-value="pk"
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :items="origins"
                    :label="$t('customer_origin')"
                    v-model="customer.origin_id"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Fecha de origen de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="origin"
                  rules="required"
                  :name="$t('customer_origin_date')"
                  v-slot="{ errors }"
                >
                  <i-date-picker
                    v-model="customer.origin_date"
                    :label="$t('customer_origin_date')"
                    :error-messages="errors[0]"
                    color="primary"
                    :max="new Date().toISOString().substr(0, 10)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row
              v-if="getPermissions([`base_config.change_globaltax`]) || isAdmin"
              class="mt-3"
            >
              <!-- Impuesto -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="tax"
                  :label="$tc('tax', 1)"
                  v-model="customer.tax"
                ></v-switch>
              </v-col>

              <!-- Número de pago de impuestos -->
              <v-col cols="12" md="8" lg="4">
                <ValidationProvider
                  v-show="!customer.tax"
                  vid="tax_id"
                  :name="$tc('tax_id', 1)"
                  :rules="customer.tax ? '' : 'required|min:4|max:50'"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('tax_id', 1)"
                    v-model="customer.tax_number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <!-- Crédito -->
              <v-col
                v-if="getPermissions([`base_config.view_term`]) || isAdmin"
                cols="12"
                md="4"
              >
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="has_credit"
                  :label="$tc('has_credit', 1)"
                  v-model="customer.has_credit"
                ></v-switch>
              </v-col>

              <!-- Límite de crédito -->
              <v-col v-show="customer.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="credit_limit"
                  :name="$t('credit_limit')"
                  :rules="{ required: customer.has_credit }"
                  v-slot="{ errors }"
                >
                  <i-money
                    v-if="customer.has_credit"
                    class="secondary--text"
                    outlined
                    :disabled="
                      !getPermissions([`base_config.change_term`]) && !isAdmin
                    "
                    :error-messages="errors[0]"
                    :label="$t('credit_limit')"
                    v-model="customer.customercredit_set[0].credit_limit"
                  ></i-money>
                </ValidationProvider>
              </v-col>

              <!-- Días para pagar -->
              <v-col v-show="customer.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="day_terms"
                  :name="$t('day_terms')"
                  :rules="{ required: customer.has_credit }"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-if="customer.has_credit"
                    class="secondary--text"
                    append-icon="fa-plus"
                    autocomplete="off"
                    item-text="days"
                    item-value="pk"
                    key="day_terms-input"
                    outlined
                    :disabled="
                      !getPermissions([`base_config.change_term`]) && !isAdmin
                    "
                    :error-messages="errors[0]"
                    :items="days"
                    :label="$t('day_terms')"
                    v-model="customer.customercredit_set[0].term"
                    @click:append="openTerms()"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-0 pb-3">
              <!-- Precio especial -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="special"
                  :label="$tc('pricespecial', 1)"
                  v-model="customer.special"
                ></v-switch>
              </v-col>
            </v-row>
            <v-tabs v-model="tab" centered icons-and-text class="mt-0">
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                {{ $tc('address', 1) }}
                <v-icon> fa-map-marked-alt </v-icon>
              </v-tab>
              <v-tab
                v-if="getPermissions([`customer.view_contact`]) || isAdmin"
                href="#tab-2"
              >
                {{ $tc('contact', 2) }}
                <v-icon> fa-address-book </v-icon>
              </v-tab>
              <v-tab href="#tab-3" v-if="customer.special">
                {{ $tc('pricespecial') }}
                <v-icon> fa-dollar-sign </v-icon>
              </v-tab>
            </v-tabs>
            <div v-if="tab === 'tab-1'" class="pb-5">
              <v-row class="infocard mx-0">
                <v-col cols="12">
                  <h4 class="primary--text text--accent-3">
                    {{ $t('billing_address') }}
                  </h4>
                </v-col>

                <!-- Dirección -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address"
                    :name="$t('address')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      class="secondary--text"
                      :error-messages="errors[0]"
                      :label="$t('address')"
                      v-model="customer.address_set[0].address1"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Dirección 2 -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address2"
                    :name="$t('address2')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="customer.address_set[0].address2"
                      :error-messages="errors[0]"
                      :label="$t('address2')"
                      class="secondary--text"
                      key="address2-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Ciudad -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="city"
                    :name="$t('city')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="customer.address_set[0].city"
                      :error-messages="errors[0]"
                      :label="$t('city')"
                      class="secondary--text"
                      key="city-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Estado -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="state"
                    :name="$t('state')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="customer.address_set[0].state"
                      :error-messages="errors[0]"
                      :label="$t('state')"
                      class="secondary--text"
                      key="state-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Código postal -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="zip_code"
                    :name="$t('zip_code')"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="customer.address_set[0].zip_code"
                      :error-messages="errors[0]"
                      :label="$t('zip_code')"
                      class="secondary--text"
                      key="zip_code-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="mt-3 pb-3">
                <!-- Igualar direcciones -->
                <v-col cols="12" md="4">
                  <v-switch
                    class="v-switch"
                    color="primary"
                    name="billing"
                    :label="$t('same_billing')"
                    v-model="sameBilling"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row class="infocard mx-0 mb-2">
                <v-col cols="12">
                  <h4 class="primary--text text--accent-3">
                    {{ $t('shipping_address') }}
                  </h4>
                </v-col>

                <!-- Dirección -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address3"
                    :name="$t('address')"
                    :rules="{ required: !sameBilling }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="secondary--text"
                      autocomplete="off"
                      key="address3-input"
                      outlined
                      :disabled="sameBilling"
                      :error-messages="errors[0]"
                      :label="$t('address')"
                      v-model="customer.address_set[1].address1"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Dirección 2 -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address4"
                    :name="$t('address')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="secondary--text"
                      autocomplete="off"
                      key="address4-input"
                      outlined
                      :disabled="sameBilling"
                      :error-messages="errors[0]"
                      :label="$t('address2')"
                      v-model="customer.address_set[1].address2"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Ciudad -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="city2"
                    :name="$t('city')"
                    :rules="{ required: !sameBilling }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="secondary--text"
                      autocomplete="off"
                      key="city-input"
                      outlined
                      :disabled="sameBilling"
                      :error-messages="errors[0]"
                      :label="$t('city')"
                      v-model="customer.address_set[1].city"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Estado -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="state2"
                    :name="$t('state')"
                    :rules="{ required: !sameBilling }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="secondary--text"
                      autocomplete="off"
                      key="state-input"
                      outlined
                      :disabled="sameBilling"
                      :error-messages="errors[0]"
                      :label="$t('state')"
                      v-model="customer.address_set[1].state"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Código postal -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="zip_code2"
                    :name="$t('zip_code')"
                    :rules="{
                      required: !sameBilling,
                      numeric: 'numeric'
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="secondary--text"
                      key="zip_code-input"
                      outlined
                      :disabled="sameBilling"
                      :error-messages="errors[0]"
                      :label="$t('zip_code')"
                      v-model="customer.address_set[1].zip_code"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <v-row v-if="tab === 'tab-2'" class="pb-5">
              <v-col cols="12">
                <contacts :value="customer.pk" :mode="'customer'" />
              </v-col>
            </v-row>
            <v-row v-if="tab === 'tab-3'" class="pb-5">
              <v-col cols="12">
                <prices mode="special" :customer="customer.pk" />
              </v-col>
            </v-row>
            <v-row class="mt-3 pb-3">
              <!-- Calificación -->
              <v-col cols="12">
                <h6>{{ $tc('rating') }}</h6>
                <v-rating
                  background-color="primary"
                  color="warning"
                  clearable
                  empty-icon="far fa-star"
                  full-icon="fas fa-star"
                  hover
                  length="5"
                  size="30"
                  v-model="customer.stars"
                ></v-rating>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <!-- Reseña -->
              <v-col cols="12">
                <v-textarea
                  class="secondary--text"
                  name="address"
                  rows="3"
                  outlined
                  :label="$t('review')"
                  v-model="customer.review"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dialterms from '../../components/terms/modal.vue'
import diallevel from '../../components/level/modal.vue'
import prices from '../../components/product/price.vue'
import contacts from '../../components/contact/list.vue'

export default {
  components: {
    dialterms,
    diallevel,
    prices,
    contacts
  },
  data() {
    return {
      dialogCustomer: false,
      dialogTerms: false,
      toEditLevel: null,
      toEditTerms: null,
      tab: 'tab-1',
      sameBilling: false,
      loading: false,
      levels: [],
      countries: [],
      days: [],
      origins: [],
      customer: {
        pk: '',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        tax: false,
        tax_id: '',
        has_credit: false,
        level_id: null,
        active: true,
        stars: null,
        review: '',
        permanent_customer: false,
        company_id: 1,
        special: false,
        notification: false,
        origin_id: '',
        origin_date: new Date().toISOString().substr(0, 10),
        address_set: [
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            address_type: 'BILL'
          },
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            address_type: 'SHIP'
          }
        ],
        customercredit_set: [{ credit_limit: 0, term: 1 }]
      }
    }
  },
  mounted() {
    this.getCustomer()
    this.getCountries()
    this.getLevels()
    this.getTerms()
    this.getCustomerOrigin()
    this.customer.company_id = this.myCompanies[0].id
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    })
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    openLevel(item) {
      this.toEditLevel = item === undefined ? null : item
      this.dialogCustomer = true
    },
    openTerms(item) {
      this.toEditLevel = item === undefined ? null : item
      this.dialogTerms = true
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.countries = country.data
    },
    async getLevels() {
      const level = await this.$api.customer.level.list({})
      this.levels = level.data.results
    },
    async getTerms() {
      const terms = await this.$api.terms.list({})
      this.days = terms.data.results
    },
    async getCustomerOrigin() {
      const origin = await this.$api.customerOrigin.list({})
      this.origins = origin.data.results
    },
    getCustomer() {
      this.dialogEdit = true
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      this.$api.customer.show({ pk: validation }).then((res) => {
        this.customer = res.data
        this.customer.company_id = res.data.company.id
        this.customer.level_id = this.customer.level.id
        if (this.customer.customercredit_set.length == 0) {
          this.customer.customercredit_set = [{ credit_limit: 0, term: 1 }]
        }
        if (this.customer.company) {
          delete this.customer.company
        }
        if (this.customer.origin) {
          this.customer.origin_id = this.customer.origin.id
        }
        delete this.customer.origin
      })
    },
    async submit() {
      try {
        this.loading = true
        if (!this.customer.has_credit) {
          this.customer.customercredit_set = []
        }
        this.customer.hasUser = false
        if (!this.customer.hasUser) {
          this.customer.user = null
        }
        if (this.customer.origin) {
          delete this.customer.origin
        }
        this.$api.customer.edit({
          pk: this.customer.pk,
          form: this.customer
        })
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('customer', 1)} ${this.$tc('edited', 2)}`
        )
        this.$router.push({ name: 'customer' })
      } finally {
        this.loading = false
      }
    },
    addLevel(evt) {
      this.getLevels()
      this.customer.level_id = evt.pk
    },
    addTerms(evt) {
      this.getTerms()
      this.customer.customercredit_set[0].term = evt.pk
    }
  }
}
</script>
